import React, { lazy, Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom'
import { ModalContainer } from "react-modal-global"
import './assets/styles.scss'
import Appbar from './components/Appbar'
import Modal from './components/modal/Modal'


const queryClient = new QueryClient()

const Content = lazy(() => import('./screens/Content'))
// const Chats = lazy(() => import('./screens/Chats'))
const Chat = lazy(() => import('./screens/Chat'))
const Shablons = lazy(() => import('./screens/Shablons'))
const ShablonForm = lazy(() => import('./screens/ShablonForm'))
const Shablon = lazy(() => import('./screens/Shablon'))
const Previewer = lazy(() => import('./screens/ParagraphPreviewer'))
const Frontpage = lazy(() => import('./screens/Frontpage'))



const Layout = () => {

  return (
    <>
      <Appbar />
      <main className='box pt-16 items-start'>
        <Suspense>
          <Outlet />
        </Suspense>
      </main>
    </>
  )
}


const router = createBrowserRouter([
  {
    path: '/',
    element: <Frontpage />,
    // children: [
    //   {
    //     path: ':uuid',
    //     element: <Chat />,
    //   },
    //   {
    //     // path: 'add',
    //     index: true,
    //     element: <Chat />,
    //   }
    // ],
  },
  {
    path: '/chat',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Chat />,
      },
      {
        path: ':uuid',
        element: <Chat />,
      },
    ],
  },
  {
    path: '/shablon',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ShablonForm />,
      },
      {
        path: ':uuid',
        children: [
          {
            index: true,
            element: <Shablon />,
          },
          {
            path: 'edit',
            element: <ShablonForm />,
          }
        ],
      },

    ],
  },
  {
    path: '/shablons',
    element: <Layout />,
    children: [
      {
        path: ':uuid',
        element: <Shablon />,
      },
      {
        // path: 'add',
        index: true,
        element: <Shablons />,
      }
    ],
  },

  {
    path: '*',
    element: <Content />,
  },
  {
    path: '/paragraphs-previewer/form/:id/:name',
    element: <Previewer />,
  }
])

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Suspense fallback={<div />}>
          <RouterProvider router={router} />
          <ModalContainer controller={Modal} />
        </Suspense>
      </HelmetProvider>
    </QueryClientProvider>
  )
}

export default App