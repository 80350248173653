import axios from 'axios'
import { useQuery } from 'react-query'
import parse from 'html-react-parser'

export const parseHTML = (html, empty = null) => {
  const options = {
    trim: true,
  }

  return html ? parse(html, options) : empty
}


export const formatDate = (timestamp, format, lang = 'en-US') => {
	if (!timestamp) return ''
 
	const options = (() => {
		switch (format) {
			case 'full': return  {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}
			case 'short': return {month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit'}
			default: return      {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}
		}
	})()

	const date = new Date(timestamp * 1000)
	const formater = new Intl.DateTimeFormat(lang, options)

	return formater.format(date)
  }

export function uuid(prefix = false) {
	return (prefix || null) + ( Math.random() * 1000).toFixed();
}

export const toggleClasses = (name, inactive = [], active = [], activate = true, dectivate = true) => {
  Array.from(document.getElementsByClassName(name)).map(({ classList }) => {
    active.push('active')
    classList.contains('active')
      ? dectivate && (active.map(c => classList.remove(c)) && inactive.map(c => classList.add(c)))
      : activate && (inactive.map(c => classList.remove(c)) && active.map(c => classList.add(c)))
    return true
  })
}

let token = null
const loadCSRFToken = async () => await fetch(`/session/token`).then(res => res.text()).then(res => token = res)

export const postToApi =  (uri, data = {}) => {
	return axios({
		method: 'post',
		url: `/api/${uri}`,
		data: data,
		headers: { 'X-CSRF-Token': token || loadCSRFToken() },
	  })

	// return axios.post(`/api/${uri}`, { 
	// 	params: params,
	// 	headers: { 'X-CSRF-Token': token || loadCSRFToken() },
	// })
	// .then(({data}) => data)  
}

export const patchToApi = (uri, data = {}) => {
	return axios({
		method: 'patch',
		url: `/api/${uri}`,
		data: data,
		headers: { 'X-CSRF-Token': token || loadCSRFToken() },
	  })
}

export const loadFromApi =  (uri, params = {}) => {
	return axios.get(`/api/${uri}`, { 
		params: params,
		headers: { 'X-CSRF-Token': token || loadCSRFToken() },
	})
	.then(({data}) => data)  
}

const entityTypes = {
	menu: ({name}) => ({
		url: () => `menu_items/${name}`,
		key: [`menu`, name],
		maxLifetime: 60 * 60 * 60 * 60 * 60,
		retry: 0,
		load: () => loadFromApi(`menu_items/${name}`)
	}),
	content: ({alias}) => ({
		key: [`content`, alias],
		isReady: alias ? true : false,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => alias && loadFromApi(`content`, {alias: alias})
	}),
	chats: () => ({
		key: [`chats`],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => loadFromApi(`chat/all`)
	}),
	chat: ({uuid}) => ({
		key: [`chat`, uuid],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`chat/${uuid}`)
	}),
	message: ({uuid}) => ({
		key: [`message`, uuid],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`message/${uuid}`)
	}),
	messages: ({uuid}) => ({
		key: [`chat`, uuid, 'messages'],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`chat/${uuid}/messages`)
	}),
	shablons: () => ({
		key: [`shablons`],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => loadFromApi(`shablon/all`)
	}),
	shablon: ({uuid}) => ({
		key: [`shablon`, uuid],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`shablon/${uuid}`)
	}),
	shablonChats: ({id}) => ({
		key: [`shablon`, id, `chats`],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`shablon/${id}/chats`)
	}),
	terms: ({vid}) => ({ // shablon_categories
		key: [`vocabulary`, vid],
		isReady: true,
		maxLifetime: 99999999999,
		retry: 0,
		load: () => uuid && loadFromApi(`vocabulary/${vid}`) 
	}),
}

export const getQuery = (entityTypeId, props = {}) => {
	const { key, load, maxLifetime, initialData, retry } = entityTypes[entityTypeId](props)

	return  {
		queryKey: key,
		initialData: initialData,
		staleTime: maxLifetime,
		retry: retry,
		queryFn: () => load(),
		onError: (error) => error,
	}	
}

export const useLoader = (entityTypeId, props = {}) => {
	const query = getQuery(entityTypeId, props)
	return useQuery(query)
}