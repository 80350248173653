import { useModalWindow } from 'react-modal-global'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { SimpleField, TextareaField, SelectField } from '../FormFields'

const ModalShablonToken = () => {
  const modal = useModalWindow()
  const { token, saveToken } = modal.params

  const onSubmit = (values) => {
    saveToken(values)
    modal.close()
  }
  
  return (
    <div className='relative z-10 flex-1 bg-white max-w-3xl cursor-default p-16'>
      { (() => {
        switch (token?.type) {
          case 'text':  return <ShablonTokenFormText token={token} onSubmit={onSubmit} />
          default: return <div></div>
        }
      })()}
    </div>
  )
}

const ShablonTokenFormText = ({ token, onSubmit }) => {
  const init = {
    type: 'text',
    id: token?.id,
    label: token?.label || '',
    widget: token?.widget || 'text',
    example: token?.example || '',
    description: token?.description || ''
  }

  const schema = Yup.object({
    label: Yup.string()
      .min(3, 'Label must be at least 3 characters')
      .required('Label is required'),
    example: Yup.string(),
    description: Yup.string(),
  })

  return (
    <Formik initialValues={init} validationSchema={schema} onSubmit={onSubmit} >
      {form => (
        <Form className={`col max-w-xl w-full relative`} >
          <div className={`col gap-6 text-l transition-all `} >
            <SimpleField name='label' label='Label' type='text' {...form} required={true} />
            <SimpleField name='example' label='Example' type='text' {...form} required={false} /> 
            <SelectField name='widget' label='Widget' options={{text: 'Text'}} {...form} required={false} />
            <TextareaField name='description' label='Description' type='text' {...form} required={false} />
            <div className='mt-4 flex gap-6'>
              <button type='submit' disabled={form.isSubmitting || !form.isValid} className={`${form.isValid ? 'bg-main' : 'bg-trans'} transition-all w-full  uppercase text-xs leading-relaxed tracking-widest text-white py-4 px-12 rounded`}>
                {form.isSubmitting ? '...' : 'Submit'}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}


export default ModalShablonToken