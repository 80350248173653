import { ModalController } from 'react-modal-global'
import ModalShablonToken from './ModalShablonToken'
// import ModalEditMyCollection from './ModalEditMyCollection'
// import ModalAddMyCollection from './ModalAddMyCollection'
// import ModalRemoveMyCollection from './ModalRemoveMyCollection'

import "react-modal-global/styles/modal.scss" 
import '../../assets/modal.scss'
const Modal = new ModalController({
  components: {
    addShablonToken: ModalShablonToken,
    // addMyCollection: ModalAddMyCollection,
    // editMyCollection: ModalEditMyCollection,
    // removeMyCollection: ModalRemoveMyCollection,
  }
})

export default Modal
