import React, { useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useLoader, toggleClasses } from '../Utils'
import Logo from '../assets/logo192.png'

// const Logo = lazy(() => import('../assets/logo192.png'))

const Appbar = () => {
  const location = useLocation()
  const { data: menu } = useLoader('menu', { name: 'main' })

  useEffect(() => { toggleMenu(false) }, [location.pathname])

  const toggleMenu = (activate = true) => {
    toggleClasses('menu', ['max-sm:-translate-x-full'], ['transition-all'], activate)
    toggleClasses('bl-1', ['w-8', '-mt-5'], ['w-6', 'rotate-45'], activate)
    toggleClasses('bl-2', ['w-8'], ['w-6', '-rotate-45'], activate)
    toggleClasses('bl-3', ['w-5', 'mr-3', '-mb-5'], ['w-0'], activate)
  }

  return (
    <div className='fixed z-50 top-0 inset-x-0 flex justify-center h-16 bg-white border-gray-300 border-b'>
      <div className='container box gap-5 justify-between'>
        <div className='col items-start justify-center'>
          <Link to='/' className='box gap-1 items-center text-sm' ><img src={Logo} alt='shablon.ai' width='32' height='32' /> shablon.ai</Link>
        </div>
        <div className='box menu gap-7 justify-end items-center max-sm:fixed max-sm:bg-white max-sm:inset-0 max-sm:top-16 max-sm:flex-col max-sm:justify-start max-sm:-translate-x-full max-sm:p-6'>
          {/* <Link to={`/shablon`} className={`flex items-center justify-center px-4 py-1.5 rounded-lg text-white bg-black my-3  `}>
            Add shablon
          </Link>
          <hr className='h-7 w-px bg-gray-400  mx-5'/> */}
          {menu && menu.map((item, i) => <MenuItem item={item} key={i} />)}

          <hr className='h-7 w-px bg-gray-400  mx-0'/>
          <Link to={`/shablon`} className={`flex items-center justify-center px-4 py-1.5 rounded-lg text-white text-sm bg-black my-3  `}>
            Add shablon
          </Link>

        </div>
        {/* {<Menu menu={menu} />} */}
        <BurgerButton toggleMenu={toggleMenu} />
      </div>
    </div>
  )
}
 
const MenuItem = ({ item }) => {
  const location = useLocation()

  return (
    <Link to={item.relative} className={`flex items-center justify-center px-0 py-1.5 rounded-lg max-sm:hover:font-bold max-sm:h-16 ${location.pathname === item.relative ? 'underline' : ''}`}>
      {item.title}
    </Link>
  )
}

const BurgerButton = ({ toggleMenu }) => {
  return (
    <button className='menu-burgers w-16 h-16 hidden items-center justify-center -mr-4 max-sm:flex' onClick={toggleMenu}>
      <span className='absolute h-0.5 bg-black transition-all bl-1 w-8 -mt-5'></span>
      <span className='absolute h-0.5 bg-black transition-all bl-2 w-8'></span>
      <span className='absolute h-0.5 bg-black transition-all bl-3 w-5 mr-3 -mb-5'></span>
    </button>
  )
}

export default Appbar