import { Field } from 'formik'
import parse from 'html-react-parser'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include Quill's CSS for styling
import '../assets/form.scss'
export const FieldLabel = ({ label, hasValue, required }) => 
  <label className={`${hasValue ? 'opacity-100' : 'opacity-0'} text-black`}>{label}{required ? ' *' : ''}</label>
  
export const FieldError = ({ error = '', show }) => (
  <div className={`field-error overflow-hidden transition-all ${show ? 'opacity-100 h-5' : 'opacity-50 h-0'} flex items-end`}>{ error && parse(error) }</div>
)

export const SimpleField = ({ type, name, label, values, errors, touched, required, className }) => {
  return (
    <div className={`field field-${type} field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
      <FieldLabel label={label} hasValue={ values[name] } required={required} />
      <Field type={type} name={name} placeholder={label + (required ? ' *' : '')} className='bg-transparent' />
      <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
    </div>
  )
}

export const CheckboxField = ({ name, label, values, errors, touched, required, className }) => {
  return (
    <div className={`field field-checkbox field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
      <label className='!flex gap-3 justify-start cursor-pointer mt-2'>
        <Field type='checkbox' name={name} className='hidden' />
        <div className={`flex-shrink-0 w-9 h-6 p-1 my-1 rounded-2xl col justify-center ${values[name] ? ' bg-main' : ' bg-trans'}`}>
          <div className={`w-4 h-4 bg-white rounded-full transition-all ${values[name] ? 'translate-x-3' : ''}`} />
        </div>
        <div className='text-black'>{ label + (required ? ' *' : '') }</div>
      </label>
      <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
    </div>
  )
}

export const PrivacyCheckboxField = ({ name, values, errors, touched, className }) => {
  return (
    <div className={`field field-checkbox field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
      <label className='!flex gap-3 justify-start cursor-pointer mt-2'>
        <Field type='checkbox' name={name} className='hidden' />
        <div className={`flex-shrink-0 w-9 h-6 p-1 my-1 rounded-2xl   transition-all ${values[name] ? ' bg-main' : ' bg-gray-400'}`}>
          <div className={`w-4 h-4 bg-white rounded-full transition-all ${values[name] ? 'translate-x-3' : ''}`} />
        </div>
        {/* <div className='text-black'>Ich habe die Hinweise zum <Link to='/datenschutz' className='underline'>Datenschutz</Link> gelesen und akzeptiere diese.*</div>  */}
        <div className='text-black'>Ich habe die <a href='/datenschutz' target='_blank' className='underline'>Datenschutzhinweise</a> gelesen und bin mit dem darin beschriebenen Umgang mit meinen personenbezogenen Daten einverstanden. Mir ist bekannt, dass ich für die von mir freiwillig zur Verfügung gestellten Daten ein Widerspruchsrecht für die Zukunft habe. *</div> 
        </label>
         
      <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
    </div>
  )
}

export const TextareaField = ({ name, label, values, errors, touched, className, max }) => {
  return (
    <div className={`field field-textarea relative field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
      <FieldLabel label={label} hasValue={ values[name] } />
      <Field as="textarea" name={name} placeholder={label} className='bg-transparent' />
      {max && <div className='text-xs text-gray-light absolute right-0'> {values[name].length} / {max} characters</div>}
      <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
    </div>
  )
}


export const EditorField = ({ name, label, values, errors, touched, className, setFieldValue }) => {
  const handleEditorChange = (value) => {
    setFieldValue(name, value)
    // setEditorContent(value);
    // console.log('Editor Content:', values[name]); // Log the HTML content
    // console.log('Editor Content:', value); // Log the HTML content
  };
  const quillModules = {
    toolbar: [['bold', 'italic']],
  };
  return (
    <div className={`field field-textarea relative field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
      <FieldLabel label={label} hasValue={ values[name] } />
      {/* <Field as="textarea" name={name} placeholder={label} className='bg-transparent' /> */}
      
      
      <ReactQuill theme="snow" value={values[name]} modules={quillModules}  onChange={handleEditorChange} placeholder="Write something..."/>



      <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
    </div>
  )
}

export const SelectField = ({ name, label, options, placeholder, values, errors, touched, className }) => {
  return (
    <div className={`field field-select field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
      <FieldLabel label={label} hasValue={values[name]} />
      <Field as='select' name={name} className={`bg-transparent mt-2 ${values?.[name] === '' ? 'text-sm text-gray-light' : 'text-lg'} `}>
        {placeholder && <option value='' className=''>{placeholder}</option>}
        {Object.keys(options).map(key => <option value={key} key={key} className=''>{options[key]}</option>)}
      </Field>
      <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
    </div>
  );
}

export const OptionsField = ({ name, label, options, values, errors, touched, className }) => {
  return (
    <div className={`field field-options field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
      <div className='mb-3 text-gray-light text-sm'>{label} *</div>
      <div role='group' aria-labelledby={name} className='flex gap-8'>
        {Object.keys(options).map(key => <Option name={name} value={key} label={options[key]} key={key} />)}
      </div>
      <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
    </div>
  )
}

export const Option = ({ name, value, label }) => (
  <div className='relative h-6 flex items-stretch'>
    <Field type='radio' name={name} value={value} id={value} className='hidden' />
    <label className='pl-8 text-gray-main flex items-center cursor-pointer' htmlFor={value}>{label}</label>
  </div>
)

export const PasswordField = ({ name, label, placeholder, values, errors, touched, className }) => {
  return (
    <div className={`field field-hasValue field-${errors?.[name] ? 'error' : values[name] ? 'valide' : 'empty'} field-${touched?.[name] ? 'touched' : 'untouched'} ${className}`}>
        {label && <FieldLabel label={label} hasValue={ values[name] } />}
        <Field type='password' name={name} placeholder={placeholder || label} />
        <FieldError error={errors?.[name]} show={errors?.[name] && touched?.[name]} />
    </div>
  )
}

export const PasswordConfirmField = ({ values, errors, touched  }) => {
  return (
    <div className='field field-text'>
      <div className='pb-5'>
        <FieldLabel label='Password' hasValue={ values.pass } />
        <Field label='Create a new Password' type='password' name='pass' placeholder='Password' />
        <FieldError error={errors?.pass} show={touched.hasOwnProperty('pass') && errors.hasOwnProperty('pass')} />
      </div>
      <div>
        <Field label='Verify Password' type='password' name='pass2' placeholder='confirm password' />
        <FieldError error={errors?.pass2} show={touched.hasOwnProperty('pass2') && errors.hasOwnProperty('pass2')} />
      </div>
    </div>
  )
}
 
